@import "../../styles/_chunk";

.fan-favourite-card {
  .content-container {
    display: flex;
    flex-direction: column;
    background-color: $card-background-dark;
    border-radius: $border-radius;

    @include theme(light) {
      background-color: map-get($light-theme, card-background);
      box-shadow: map-get($light-theme, card-box-shadow);
      margin-bottom: map-get($light-theme, card-slider-margin);
      margin-top: map-get($light-theme, card-slider-margin);
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: space-between;
    }

    .left-container {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      flex-shrink: 0;

      .card-actions-container {
        height: 100%;
        width: 100%;
        padding-top: 3rem;
        display: none;

        @include media-breakpoint-up(md) {
          display: block;
        }

        @include media-breakpoint-up(lg) {
          position: absolute;
          padding-top: 0;
        }

        .wishlist-button {
          right: 8px;
          top: 8px;
          position: absolute;
          z-index: 7;
          border-radius: 15px;
          height: 30px;
          width: 30px;
          padding: 0;
          align-items: center;
          display: flex;
          justify-content: center;
          background-color: rgba($black, 0.6);

          &:hover {
            svg {
              @include brand-property(color, brand-core);
            }
          }

          button.wishlist-add {
            @include brand-property(color, brand-core);
          }

          svg {
            font-size: 1rem;
          }
        }

        .card-price-container {
          position: absolute;
          bottom: 8px;
          right: 8px;
          z-index: 7;
          background-color: $card-background-dark;

          @include theme(light) {
            background-color: map-get($light-theme, card-background);
          }

          @include media-breakpoint-up(lg) {
            .card-saving {
              border-radius: 0;
              min-height: 40px;
            }

            .price-wasprice-container {
              padding: 5px;
            }
          }
        }
      }

      @include media-breakpoint-up(md) {
        width: 45%;
      }

      .responsive-image {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;

        @include media-breakpoint-up(md) {
          border-top-right-radius: 0;
        }

        @include media-breakpoint-up(lg) {
          border-bottom-left-radius: $border-radius;
        }

        img {
          overflow: hidden;
          border-top-left-radius: $border-radius;
          border-top-right-radius: $border-radius;

          @include media-breakpoint-up(md) {
            border-top-right-radius: 0;
          }

          @include media-breakpoint-up(lg) {
            border-bottom-left-radius: $border-radius;
          }
        }
      }

      .cover-container {
        position: relative;

        .cover-video-container {
          video {
            width: 100%;
            height: 100%;
            position: absolute;
            border-top-left-radius: $border-radius;
            border-top-right-radius: $border-radius;
          }
        }

        .infinite-loader {
          transition: opacity 0.2s linear 0.2s;
          position: absolute;
          opacity: 0;
          top: 25%;
          width: 100%;
          z-index: 1;

          @include media-breakpoint-only(md) {
            top: 0;
          }

          .spinner > div {
            background-color: hsla(0, 0%, 100%, 0.6);
          }
        }

        .screenshot-carousel {
          transition: opacity 0.3s linear;
          opacity: 0;

          .screenshot-overlay {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.3));
          }

          @include theme(light) {
            background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.2));
          }

          .screenshot-progress-dot-container {
            position: absolute;
            left: 0;
            right: 0;
            display: flex;
            flex: 1 1 auto;
            width: 100%;
            box-sizing: border-box;
            padding: 0 16px;

            .screenshot-progress-dot {
              width: 100%;
              height: 2.8px;
              box-sizing: border-box;
              background-color: hsla(0, 0%, 100%, 0.4);
              border-radius: 3.8px;
            }

            .active-dot {
              background-color: $white;
            }

            .screenshot-progress-dot:not(:first-child) {
              margin-left: 8px;
            }
          }

          .screenshot-progress-dot-container-small {
            bottom: 12px;
          }

          .screenshot-progress-dot-container-medium {
            bottom: 19px;
          }

          .screenshot-progress-dot-container-large {
            bottom: 22px;
          }

          .screenshot-fallback {
            background-color: $card-background-dark;
            height: 100%;
            width: 100%;
            position: absolute;

            @include theme(light) {
              background-color: map-get($light-theme, card-background);
              box-shadow: map-get($light-theme, card-box-shadow);
            }

            .screenshot-fallback-content {
              top: 0;
              left: 0;
              height: 100%;
              width: 100%;
              position: absolute;
              display: flex;
              flex-direction: column;
              justify-content: center;
              color: $text-white;
              font-weight: 700;
              text-align: center;
              font-size: 1.2rem;
              padding-left: 3rem;
              padding-right: 3rem;

              @include theme(light) {
                color: map-get($light-theme, font-color);
              }
            }

            .screenshot-fallback-content-small {
              font-size: 1rem;
            }

            .screenshot-fallback-content-medium {
              font-size: 1.2rem;
            }

            .screenshot-fallback-content-large {
              font-size: 1.4rem;
            }
          }

          &.tall-screenshots {
            .screenshot-container {
              position: relative;
              width: 100%;
              padding-top: 56.25%; /* 16:9 Aspect Ratio */
              overflow: hidden;
              border-top-left-radius: $border-radius-lg;
              border-top-right-radius: $border-radius-lg;

              .screenshot-image {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                display: flex;
                justify-content: center;

                img {
                  width: auto;
                  height: 100%;
                }

                img.screenshot-bg {
                  position: absolute !important;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  width: 100%;
                  height: auto;
                  filter: blur(20px) brightness(0.9);
                  transform: scale(1.1);
                }
              }

              .screenshot-fallback {
                top: 0;
                left: 0;
              }
            }
          }
        }
      }
    }

    .right-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 0.65rem;

      @include media-breakpoint-up(md) {
        padding: 1.25rem;
      }

      @include media-breakpoint-up(xl) {
        padding: 1.25rem 1.5rem;
      }

      .star-rating-container {
        display: none;

        @include media-breakpoint-up(md) {
          padding-bottom: 1rem;
          display: flex;
        }
      }

      .name-container {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        margin-bottom: 0.75rem;
        color: $text-white;
        font-weight: 400;
        font-size: 1.125rem;

        @include media-breakpoint-up(md) {
          font-size: 1.25rem;
        }

        @include media-breakpoint-up(xl) {
          font-size: 1.375rem;
        }

        @include media-breakpoint-up(xxl) {
          font-size: 1.5rem;
        }

        @include theme(light) {
          color: $text-black;
        }
      }

      .price-rating-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 0.75rem;

        .star-rating-container {
          display: flex;
        }

        .card-price-container {
          .card-saving {
            position: relative;
            margin-left: 0;
          }
        }

        @include media-breakpoint-up(md) {
          display: none;
        }
      }

      .tag-column-container {
        display: flex;
        flex-wrap: nowrap;
        padding-bottom: 0.5rem;

        .tag-column {
          padding: 0.25rem 0.25rem 0.25rem 0;

          @include media-breakpoint-only(xs) {
            &:nth-child(n+4) {
              display: none;
            }
          }

          @include media-breakpoint-only(sm) {
            &:nth-child(n+6) {
              display: none;
            }
          }

          @include media-breakpoint-only(md) {
            &:nth-child(n+4) {
              display: none;
            }
          }

          @include media-breakpoint-only(lg) {
            &:nth-child(n+5) {
              display: none;
            }
          }

          @include media-breakpoint-only(xl) {
            &:nth-child(n+6) {
              display: none;
            }
          }
        }

        .tag {
          background-color: $grey-10;
          color: $text-white;
          border-radius: $border-radius-sm;
          font-size: 12px;
          font-weight: 400;
          padding: 0.125rem 0.375rem;
          white-space: pre;

          @include theme(light) {
            background-color: $grey-3;
            color: $text-black;
          }
        }
      }

      .review-text,
      .review-text-lg,
      .review-text-xl,
      .review-text-md,
      .review-text-sm,
      .review-text-xs,
      .review-text-xxl {
        display: none;
        font-size: 0.875rem;

        @include media-breakpoint-up(xl) {
          font-size: 0.935rem;
        }

        height: 70%;
      }

      .review-text-xs {
        @include media-breakpoint-only(xs) {
          min-height: 4.375rem;
          display: block;
        }
      }

      .review-text-sm {
        @include media-breakpoint-only(sm) {
          display: block;
        }
      }

      .review-text-md {
        @include media-breakpoint-only(md) {
          display: block;
        }
      }

      .review-text-lg {
        @include media-breakpoint-only(lg) {
          display: block;
        }
      }

      .review-text-xl {
        @include media-breakpoint-only(xl) {
          display: block;
        }
      }

      .review-text-xxl {
        @include media-breakpoint-up(xxl) {
          display: block;
        }
      }

      .full-review-link {
        @include brand-property(color, brand-core);

        font-weight: 400;
        margin-top: auto;
        margin-bottom: 0.5rem;
        padding-top: 0.5rem;
        font-size: 0.75rem;

        @include media-breakpoint-up(md) {
          padding-top: 0;
        }

        @include media-breakpoint-up(lg) {
          font-size: 0.875rem;
        }

        @include media-breakpoint-up(xxl) {
          font-size: 1rem;
        }
      }

      .bottom-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 400;
        color: $grey-5;
        margin-top: auto;
        font-size: 0.75rem;

        @include media-breakpoint-up(lg) {
          font-size: 0.875rem;
        }

        @include media-breakpoint-up(xxl) {
          font-size: 1rem;
        }

        .like-container {
          display: flex;
          align-items: center;

          .found-helpful-message {
            display: none;
            margin-right: 1rem;

            @include media-breakpoint-only(sm) {
              display: block;
            }

            @include media-breakpoint-up(lg) {
              display: block;
            }
          }

          .like-button {
            background-color: $thumb-green;
            font-weight: 700;

            @include media-breakpoint-down(lg) {
              padding: 0.2rem 0.5rem;
              font-size: 0.75rem;
            }

            &:hover {
              cursor: unset;
            }

            .like-button-icons-container {
              display: flex;
              align-items: center;

              .like-count-container {
                margin-left: 5px;
                margin-right: -5px;
                border-radius: $border-radius-sm;
                font-size: 12px;
                width: 22px;
                height: 22px;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all 0.15s ease-in-out;

                @include media-breakpoint-up(xl) {
                  background-color: $thumb-green-dark;
                  margin-left: 12px;
                }
              }
            }
          }
        }
      }

      @include media-breakpoint-up(md) {
        width: 55%;
      }
    }
  }
}
