$dark-theme: (
  background-color: $grey-14,
  secondary-background-color: $grey-16,
  font-color: $font-color-light,
  font-color-mid: $grey-4,
  card-background: $grey-12,
  card-icons-color: $grey-2,
  card-box-shadow: 0 2px 8px rgba($black, 0.12),
  saving-percentage-background: $black,
  font-weight-regular: 300,
  font-weight-bold: 700,
);

$light-theme: (
  background-color: $grey-1,
  secondary-background-color: $grey-3,
  font-color: $font-color-dark,
  font-color-mid: $grey-5,
  card-background: $white,
  card-icons-color: $grey-7,
  card-box-shadow: 0 2px 8px rgba($black, 0.12),
  card-slider-margin: 8px, // This is to compensate for the shadow above
  saving-percentage-background: $grey-3,
  input-border-color: $grey-3,
  font-weight-light: 300,
  font-weight-regular: 400,
  font-weight-bold: 700,
);

// PARTNER BRAND THEMES

:root {
  --brand-core: #{$primary-core}; // Main brand colour
  --brand-dark: #{$primary-dark}; // Darkened main brand colour for hover effects
  --font-color: #{$text-black}; // Text colour to go ontop of brand-core
}

.brand-hpomen {
  --brand-core: #{$hpomen-core};
  --brand-dark: #{$hpomen-dark};
  --font-color: #{$text-black};

  font-family: 'Montserrat', 'Verdana', sans-serif;
}

.brand-acer {
  --brand-core: #{$acer-core};
  --brand-dark: #{$acer-dark};
  --font-color: #{$white};
}

.brand-gamespot {
  --brand-core: #{$gamespot-core};
  --brand-dark: #{$gamespot-dark};
  --font-color: #{$white};
}

.brand-box {
  --brand-core: #{$box-core};
  --brand-dark: #{$box-dark};
  --font-color: #{$white};
}

.brand-chilliblast {
  --brand-core: #{$chilliblast-core};
  --brand-dark: #{$chilliblast-dark};
  --font-color: #09212d;
}

.brand-ccl {
  --brand-core: #{$ccl-core};
  --brand-dark: #{$ccl-dark};
  --font-color: #{$white};
}

.brand-insidetech {
  --brand-core: #{$insidetech-core};
  --brand-dark: #{$insidetech-dark};
  --font-color: #{$white};
}

.brand-stormforce {
  --brand-core: #{$stormforce-core};
  --brand-dark: #{$stormforce-dark};
  --font-color: #{$black};
}
