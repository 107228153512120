h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 400;
}

h1,
.h1 {
  font-size: 1.625rem;

  @include media-breakpoint-up(md) {
    font-size: 1.75rem;
  }

  @include media-breakpoint-up(xl) {
    font-size: 1.875rem;
  }

  @include media-breakpoint-up(xxl) {
    font-size: 2rem;
  }
}

h2,
.h2 {
  font-size: 1.375rem;

  @include media-breakpoint-up(md) {
    font-size: 1.5rem;
  }

  @include media-breakpoint-up(xl) {
    font-size: 1.625rem;
  }

  @include media-breakpoint-up(xxl) {
    font-size: 1.75rem;
  }
}

h3,
.h3 {
  font-size: 1.125rem;

  @include media-breakpoint-up(md) {
    font-size: 1.25rem;
  }

  @include media-breakpoint-up(xl) {
    font-size: 1.375rem;
  }

  @include media-breakpoint-up(xxl) {
    font-size: 1.5rem;
  }
}

h4,
.h4 {
  font-size: 1rem;

  @include media-breakpoint-up(xl) {
    font-size: 1.125rem;
  }

  @include media-breakpoint-up(xxl) {
    font-size: 1.25rem;
  }
}

h5,
.h5 {
  font-size: 1rem;

  @include media-breakpoint-up(xxl) {
    font-size: 1.125rem;
  }
}

h6,
.h6 {
  font-size: 1rem;
}
